export default {
    data() {
        return {
            mix: '混入变量'
        }
    },
    methods: {
        mixDemo() {
            console.log('混入打印');
        }
    },
}