import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/Start',
		name: 'Start',
		component: () => import( /* webpackChunkName: "about" */ '../views/boss/Start.vue')
	},
	{
		path: '/',
		redirect: '/Start' //默认显示
	}, {
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
	},
	{
		path: '/login',
		name: 'login',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/logins/login.vue')
	},
	{
		path: '/CourseTopics',
		name: 'CourseTopics',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/CourseTopics.vue')
	},
	{
		path: '/Catalog',
		name: 'Catalog',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/Catalog.vue')
	},
	{
		path: '/exhome',
		name: 'exHome',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/exam/home.vue')
	},
	{
		path: '/exe',
		name: 'exercise',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/exam/exercise.vue')
	},
	{
		path: '/my',
		name: 'mine',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/mine.vue')
	},
	{
		path: '/Set',
		name: 'setting',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/setting.vue')
	},
	{
		path: '/subject',
		name: 'subjectList',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/exam/subjectList.vue')
	},
	{
		path: '/imitate',
		name: 'moni',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/exam/imitate.vue')
	},
	{
		path: '/trainingPlan',
		name: 'plan',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/trainingPlan.vue')
	},
	{
		path: '/notice',
		name: 'notice',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/base/notice.vue')
	},
	{
		path: '/noticeDe',
		name: 'noticeDe',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/base/noticeDe.vue')
	},
	{
		path: '/view',
		name: 'view',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/pdf/view.vue')
	},
	{
		path: '/f',
		name: 'F',
		component: () => import( /* webpackChunkName: "about" */ '../views/routers/F.vue'),
		children: [{
				path: '/z',
				name: 'Z',
				component: () => import( /* webpackChunkName: "about" */ '../views/routers/Z.vue')
			},
			{
				path: '/z2',
				name: 'Z2',
				component: () => import( /* webpackChunkName: "about" */ '../views/routers/Z2.vue')
			},
		]
	},

	{
		path: '/denglu',
		name: 'Denglu',
		component: () => import( /* webpackChunkName: "about" */ '../views/boss/Denglu.vue'),
	},
	{
		path: '*',
		name: 'Error',
		component: () => import( /* webpackChunkName: "about" */ '../views/boss/Error.vue'),
	},
	// {
	//   path: 'ab',
	//   name: 'Ab',
	//   component: () => import(/* webpackChunkName: "about" */ '../views/boss/Ab.vue'),
	// },
	{
		path: '/person',
		name: 'Person',
		component: () => import( /* webpackChunkName: "about" */ '../views/boss/Person.vue'),
	},
]

const router = new VueRouter({
	routes,
	//to:即将要进入的目标 路由对象
	//from:当前导航正要离开的路由
	scrollBehavior(to, from, savedPosition) {
		//return 期望滚动到哪个的位置
		//按照历史记录重置滚动条位置
		// if (savedPosition) {
		//   return savedPosition
		// } else {
		//   return { x: 0, y: 0 }
		// }
		//始终重置为顶部位置
		return {
			x: 0,
			y: 0
		}
	}

})
router.beforeEach((to, from, next) => {
	console.log(to, from);
	next();
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

export default router