<template>
	<div id="app">
		<div v-if="$route.path!='/login'" class="top">
			<div class="top_con">
				<div style="flex: 1;" class="flex align-center">
					<ul class="top_l">
						<img @click="goHome()" src="./assets/img/logo.png" alt="" class="logo" />

						<li @click="goHome()">首页</li>
						<li @click="goTop()">课程专题</li>
						<li @click="goPlan()">培训计划</li>

						<li @click="goPdf()">课件查看</li>
						<li @click="goExam()">考试</li>
						<li @click="goNew()">公告</li>
					</ul>
					<!-- <div class="input_search">
						<el-input @change="inputChange" placeholder="请输入关键词" suffix-icon="el-icon-search"
							v-model="input6">
						</el-input>
					</div> -->
				</div>

				<div v-if="user" class="person_info" @click="goMine()">
					<img class="avatar" :src="user.headImage?user.headImage:require('./assets/icon/avatar.png')"
						alt="" />
					{{user.name}}
				</div>
				<!-- <div v-else class="text-gray">
					<span @click="goLogin()" class="hand">登录</span>
					<span style="margin: 0 10px;">/</span>
					<span class="hand">注册</span>
				</div> -->
			</div>


		</div>
		<router-view />
		<div v-if="$route.path!='/login'" class="last">
			<div style="width: 70%;margin: 0 auto;">
				<div class="last_l">
					<div class="">
						<ul class="last_ula">
							<li>快速导航</li>
							<li class="flex">
								<span @click="goTop()">课程专题</span>
								<span @click="goExam()">考试</span>
							</li>
							<li class="flex">
								<span @click="goChild(3)">我的证书</span>
								<span @click="goChild(6)">错题反馈</span>
							</li>
							<li class="flex">
								<span @click="goChild(5)">我的收藏</span>
								<span @click="goChild(8)">关于我们</span>
							</li>
							<li @click="goChild(7)">
								意见反馈
							</li>
						</ul>
						<ul class="last_ulb">
							<li>联系我们</li>
							<li class="flex">
								<img class="icon" src="../src/assets/icon/icon1.png" alt="" />
								联系电话:{{telInfo?telInfo.phone:'暂无'}}
							</li>
							<!-- <li class="flex">
								<img class="icon" src="../src/assets/icon/icon2.png" alt="" />
								E-mail:12222
							</li>
							<li class="flex">
								<img class="icon" src="../src/assets/icon/icon3.png" alt="" />
								公司名称:12222
							</li>
							<li class="flex">
								<img class="icon" src="../src/assets/icon/icon4.png" alt="" />
								公司地址:12222
							</li> -->
						</ul>
					</div>
					<!-- <div class="last_r">
						<img class="ewm" src="../src/assets/2.png" alt="" />
						<p>扫码下载APP</p>
					</div> -->
				</div>
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">宁ICP备20000369号</a>
			</div>

			<div class="last_b">

			</div>
		</div>
	</div>
</template>
<script>
	import start from '../src/views/boss/Start.vue'
	import {
		HttpRequest
	} from '@/utils/http';
	export default {
		data() {
			return {
				isLogin: true,
				input6: '',
				user: {},
				telInfo: {}
			}
		},
		mounted() {
			var info = localStorage.getItem("userInfo")
			if (info && info != null && info != '' && info != 'null') {
				this.getUserInfo()
				this.telWeFun()
			}

		},
		// created() {

		// 	this.$nextTick(() => {
		// 		// 在DOM更新后执行其他操作

		// 		console.log(localStorage.getItem("userInfo"))
		// 		this.getUserInfo()
		// 		this.telWeFun()
		// 	})
		// },
		methods: {
			inputChange(e) {},
			getUserInfo() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/home/getUserInfo"
				}).then(res => {
					console.log(res.data.data)
					this.user = res.data.data
					localStorage.setItem("userInfo", JSON.stringify(res.data.data))
				})
			},
			goPdf() {
				this.$router.push('../view');
			},
			goHome() {
				this.$router.push('/Start');
			},
			goTop() {
				this.$router.push('../CourseTopics');
			},
			goPlan() {
				this.$router.push('../trainingPlan');
			},
			goExam() {
				this.$router.push('../subject');
			},
			goNew() {
				this.$router.push('../notice');
			},
			goMine() {
				this.$router.push('../my');
			},
			goChild(e) {
				this.$router.push('../my?index=' + e);
			},
			//联系我们
			telWeFun() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/my/getContactUs",
				}).then(res => {
					console.log(res.data.data)
					this.telInfo = res.data.data
				}).catch(err => {});
			},
		}
	}
</script>

<style>
	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0.1);
		border-radius: 0;
	}

	::-webkit-scrollbar-thumb {
		cursor: pointer;
		border-radius: 5px;
		background: #227BF9;
		transition: color 0.2s ease;
	}

	::-webkit-scrollbar-thumb:hover {
		background: rgba(0, 0, 0, 0.3);
	}

	.hand {
		cursor: pointer;
	}

	.blue {
		color: #227BF9;
	}

	.justify-center {
		justify-content: center;
	}

	.just-between {
		justify-content: space-between;
	}

	.text-bold {
		font-weight: 600;
	}

	.text-lg {
		font-size: 16px;
	}

	.margin-top-sm {
		margin-top: 21px;
	}

	.text-gray {
		color: #999;
	}

	.text-white {
		color: #fff;
	}

	.bg-white {
		background-color: #fff;
	}

	.flex {
		display: flex;
	}

	.shenglue {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		/* -webkit-box-orient: vertical; */
		word-break: break-all;
	}

	.flex1 {
		flex: 1;
	}

	.input_search {
		width: 18%;
		margin-left: 40px;
	}

	.input_search .el-input__inner {
		background-color: #F5F7FA;
		height: 44px;
		line-height: 44px;
	}

	.input_search .el-input__suffix {
		color: #227BF9;
	}

	.input_search .el-input__icon {
		font-size: 15px;
	}

	.person_info {
		cursor: pointer;
		display: flex;
		align-items: center;

	}

	.top .logo {
		height: 46px;
		margin-right: 30px;
	}

	.align-center {
		align-items: center;
	}

	body {
		background-color: rgb(235, 235, 235);
		width: 100%;
		height: 100%;
		overflow-x: hidden;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		/* background: url('../assets/back.jpg') no-repeat; */
	}

	#nav {
		padding: 30px;
	}

	#nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}

	.top {
		background-color: #fff;
		padding: 20px 0;
		color: #000;
		text-align: center;
		/* margin-bottom: 10px; */
		box-shadow: 10px 5px 5px rgba(214, 214, 214, 0.2);
	}

	.top_con {
		width: 70%;
		display: flex;
		margin: 0 auto;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.top .avatar {
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}

	.top ul {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.top ul li {
		color: #666;
		margin: 0 15px;
		font-size: 18px;
	}

	.top li:hover {
		color: #1B8AFB;
		cursor: pointer;
	}

	.last {
		position: absolute;
		z-index: 9999;
		/* bottom: 0; */
		width: 100%;
		left: 0;
		right: 0;
		/* height: 260px; */
		background-color: white;
		padding: 20px 0;
	}

	.last .icon {
		height: 18px;
		margin-right: 7px;
	}

	.last .flex {
		align-items: center;
	}

	.last_l {
		display: flex;
		padding-bottom: 20px;
		flex-direction: row;
	}

	.last_l span {
		text-align: left;
		cursor: pointer;
	}

	.last_l ul {
		float: left;
		margin-right: 200px;
	}

	.last_l ul li {
		line-height: 34px;
		text-align: left;
		color: #545C63;
		font-size: 15px;
	}

	.last_l ul li span {
		margin-right: 30px;
	}

	.last_l ul li:first-child {
		line-height: 40px;
		font-size: 18px;
		color: #545C63;
	}

	.last_b_lv span:hover {
		color: #1B8AFB;
		cursor: pointer;
	}

	.last_r {
		text-align: right;
		float: right;
		margin-left: 460px;
		color: #545C63;

		p {
			font-size: 13px;
		}
	}

	.last_b {
		text-align: center;
		font-size: 12px;
		padding-top: 20px;
		border-top: 1px solid #F5F7FA;
	}

	.last_b p {
		line-height: 30px;
	}

	.last_b p:first-child {
		color: gray;
	}

	.last_b span {
		margin: 6px;
	}

	.jingguo:hover {
		color: #1B8AFB;
		cursor: pointer;
	}

	.icon-weibo img {
		width: 40px;
		height: 40px;
		border: 1px solid gainsboro;
		border-radius: 50%;
	}

	.icon-weibo {
		float: right;
		margin-left: 40px;
		margin-bottom: 150px;
	}

	.icon-weibo img:hover {
		border: 1px solid gray;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		cursor: pointer;
	}

	.last_ula li:hover,
	.last_ulb li:hover {
		color: #1B8AFB;
	}

	.ewm {
		width: 100px;
		height: 100px;
	}
</style>