import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import { moduleA, moduleB } from './aa'
export default new Vuex.Store({
  // 数据储存
  state: {
   username:'',
   password:'',
   address: "全国",
   tel:""
  //  title:'',
  //  value1:''
  },
  // 用来修改state数据
  // 通过store.commit触发
  mutations: {
    // 获取数据
    setCount(state) {
      state.count++;
    },
    login(state,val){
    state.username=val
  },
  denglu(state,val){
    state.tel=val
  },
  setAddress(state, val) {
    state.address = val
    localStorage.setItem("address", val)
}
  // cun(state,val){
    
  //   state.value1=val
  // },
  // cuna(state,val){
  //   state.title=val
  // }
  },
  
  getters:{
    doneTodosCount(state){
      return state.username
    },
    doneTodosCount(state){
      return state.tel
    }
  },
  // 可包含异步操作
  // 通过store.dispatch('increment')触发
  actions: {
  },
  // 将store分割成模块
  modules: {
    moduleA,moduleB
  }
})
