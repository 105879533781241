import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import About from './views/About.vue'
//混入
import mixin from './views/minxin/mixin'
Vue.mixin(mixin)
import {
	CountDown
} from 'vant';

Vue.use(CountDown);
import axios from 'axios';
Vue.prototype.$axios = axios //全局注册，使用方法为:this.$axios

// 引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

//引入css样式
import "./style/reset.css"
Vue.component("about", About)

Vue.config.productionTip = false

new Vue({
	router,
	store,

	render: h => h(App)
}).$mount('#app')