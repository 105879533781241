<template>
	<div>
		<div class="con_nav">
			<div class="con_l">
				<div class="course_l" v-for="item in courseCate.slice(0,5)" :key="item.id">
					<div class="flex align-center flex1" @click="goCate(item.title)">
						<div>{{item.title}}:</div>
						<div style="-webkit-box-orient: vertical;flex: 1;text-align: left"
							class="flex shenglue course_l_cate align-center">
							<div class="" v-for="(val,index) in item.list.slice(0,2)" :key="index">
								{{val.title}}<span class="space" v-if="index!=item.list.length-1&&index!=1">/</span>
							</div>
						</div>
					</div>

					<img class="arrow-right" src="./img/arrow_right.png" />
				</div>
			</div>
			<div class="con_r">
				<el-carousel trigger="click" height="350px">
					<el-carousel-item v-for="item in swiperList" :key="item.id">
						<img :src="item.image" alt="" />
					</el-carousel-item>
				</el-carousel>
			</div>

		</div>
		<div class="text-center Boutique">精品课程</div>
		<div class="courses">
			<div @click="goCourse(item.id)" :key="item.id" v-for="item in courseList.slice(0,9)" class="course_item">
				<img :src="item.image" class="course_img" alt="" />
				<div class="shenglue course_item_title">{{item.title}}</div>
				<div class="flex course_item_footer align-center">
					<div>共<span class="red">{{item.chapterNumber}}</span>节课</div>
					<div><span>{{item.virtuallyLearnersNumber}}</span>人学习</div>
				</div>
			</div>
		</div>
		<div class="more" @click="goMore()">查看更多</div>


		<!-- 忘记密码弹窗 -->
		<el-dialog width="18%" class="login_pop" :show-close="false" :visible.sync="forgetShow">
			<div class="flex register_top">
				<div class="flex1 text-center text-bold text-lg">忘记密码</div>
				<img @click="forgetShow=false" src="../../assets/icon/close.png" alt="" />
			</div>

			<el-input v-model="phone" maxlength="11" placeholder="请输入手机号"></el-input>
			<div class="flex align-center ewm_wrapper">
				<el-input style="flex: 1;" v-model="phone" placeholder="请输入验证码"></el-input>
				<span class="blue" @click="getCode()" v-if="codeShow">获取验证码</span>
				<span v-else @click="getCode()">重新发送({{count}}s)</span>
			</div>
			<el-input v-model="phone" maxlength="18" placeholder="请输入新密码" show-password></el-input>
			<el-input v-if="activeName=='first'" v-model="password" show-password placeholder="请再次输入新密码"></el-input>
			<el-button type="primary" class="login_btn" round>确定</el-button>

		</el-dialog>
	</div>
</template>
<script>
	import {
		HttpRequest
	} from '@/utils/http';

	import {
		mapState
	} from "vuex";
	import {
		Message
	} from "element-ui";
	export default {
		data() {
			return {
				swiperList: [],
				courseList: [], //精品课程
				loginInfo: {
					phone: '17660556955',
					code: '',
					password: '123456',
					type: 2
				},
				timer: null, //设置计时器,
				count: "",
				codeShow: true,
				checked: false,
				password: '',
				phone: '',
				activeName: 'first',
				loginShow: false,
				registerShow: false,
				forgetShow: false,
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤鸭'
				}],
				enter: '', //所属企业
				depart: '', //所属部门
				courseCate: []
			};
		},
		computed: {
			// 获取用户名 name(){
			//         return this.$store.state.username
			//   },
			...mapState(["tel"]),
		},
		mounted() {
			this.getUserInfo()
			var info = localStorage.getItem("userInfo")
			if (info && info != null && info != '' && info != 'null') {

				this.getInfo()
				this.getCourseCate()

			}
		},

		created() {
			window.addEventListener("scroll", this.windowSCroll);
		},
		methods: {
			getUserInfo() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/home/getUserInfo"
				}).then(res => {
					console.log(res.data.data)

					localStorage.setItem("userInfo", JSON.stringify(res.data.data))
				})
			},
			getUser() {
				this.getUserInfo()
				return localStorage.getItem("userInfo")
			},
			goCate(e) {
				this.$router.push('../CourseTopics?checkid=' + e);
			},
			goMore() {
				this.$router.push('../CourseTopics');
			},
			goCourse(e) {
				this.$router.push('../Catalog?id=' + e);
			},
			getCourseCate() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/home/courseTopic"
				}).then(res => {
					console.log(res.data)
					this.courseCate = res.data.data
				}).catch(err => {});
			},
			getCode() {
				if (this.loginShow = true && this.loginInfo.phone == '') {
					Message.error('请输入手机号')
					return
				}
				var data = {
					phone: this.loginInfo.phone
				}
				HttpRequest.getRequest({
					method: "POST",
					data: data,
					url: "/app/login/sendCode"
				}).then(res => {
					this.codeBtn()
				}).catch(err => {
					Message.error(err.msg)
				});

			},
			codeBtn() {
				let TIME_COUNT = 60;
				if (!this.timer) {
					this.count = TIME_COUNT;
					// this.isGrey = true;
					this.codeShow = false;
					// this.dis = true;
					this.timer = setInterval(() => {
						if (this.count > 0 && this.count <= TIME_COUNT) {
							this.count--;
						} else {
							// this.dis = false;
							// this.isGrey = false;
							this.codeShow = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000);
				}
			},
			loginSub() {
				if (this.loginInfo.password == '' && this.activeName == 'first') return Message.error('请输入密码')
				if (this.loginInfo.code == '' && this.activeName == 'second') return Message.error('请输入验证码')
				if (this.loginInfo.phone == '') return Message.error('请输入手机号码')
				if (this.checked == false) return Message.error('请勾选用户协议和隐私政策')
				HttpRequest.getRequest({
					method: "POST",
					data: this.loginInfo,
					url: "/app/login/appLogin"
				}).then(res => {
					console.log(res.data.data)
					localStorage.setItem("token", res.data.data.token)
					localStorage.setItem("headImage", res.data.data.headImage)
					localStorage.setItem("nickName", res.data.data.nickName)
				}).catch(err => {
					Message.error(err.msg)
				});
			},
			handleClick(e) {
				console.log(e)
				this.activeName = e.name
				this.loginInfo.type = e.index + 1
			},
			login() {
				this.loginShow = true
			},
			register() {
				this.registerShow = true
			},
			getInfo() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/home/getHomeInfo"
				}).then(res => {
					console.log(res.data.data)
					var data = res.data.data
					this.swiperList = data.carouselList
					this.courseList = data.courseList
				});

			},
			inputChange(e) {
				console.log(e, 123)
			},
			//热门职位
			zhiweis() {
				this.$axios({
						method: "get",
						url: "http://8.131.78.129:3000/boss/getdata?name=recommend ", // 接口地址
						data: {
							...this.info,
						},
					})
					.then((response) => {
						console.log(response, "success"); // 成功的返回
						this.arr = response.data.data;
						console.log(this.arr);
					})
					.catch((error) => console.log(error, "error")); // 失败的返回
			},
			huoqu() {
				var that = this;
				this.timerf();
				this.timer = setInterval(function() {
					that.timerf();
				}, 1000);
			},
			timerf() {
				if (this.ms == "获取") {
					this.ms = 60;
				}
				if (this.ms <= 1) {
					this.ms = "获取";
					clearInterval(this.timer);
					return;
				}
				this.ms--;
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			// handleClick(tab, event) {
			//   this.$axios({
			//     method: "post",
			//     url: "http://8.131.78.129:3000/boss/login", // 接口地址
			//     data: {
			//       phone: "18521721605",
			//       password: "666666",
			//     },
			//   })
			//     .then((response) => {
			//       console.log(response, "success"); // 成功的返回
			//       //     this.$store.commit("denglu", this.tel);
			//       // this.$router.push("/start2");
			//     })
			//     .catch((error) => console.log(error, "error")); // 失败的返回
			//   console.log(tab, event);
			// },
			tiao1() {
				this.$router.push("./Zhiwei");
				//  localStorage.setItem("arr2", JSON.stringify(this.arr2));
			},
			denglu() {
				this.$router.push("./Denglu");
			},
			zhuce() {
				this.$router.push("./Zhuce");
			},
			zhiwei() {
				this.$router.push("./Zhiwei-top");
			},
			windowSCroll() {
				//滚动条距离顶部的距离
				{
					this.pageY = window.pageYOffset;
				}
			},
			tabs(id) {
				console.log(id);
				this.active = id;
			},
			handleChange(value) {
				console.log(value);
			},
			qh(name) {
				console.log(name);
				this.defcity = name;
				this.dialogTableVisible = false;
			},
			showCont: function() {
				this.show = !this.show;
			},
			qhcity: function() {
				this.shows = true;
			},
			close() {
				this.shows = false;
			},
		},
	};
</script>

<style scoped lang='less'>
	.blue {
		color: #1B8AFB;
	}

	.red {
		color: #FF7777;
		cursor: pointer;
	}

	.register_top {
		text-align: center;
		display: flex;
	}

	.login_pop /deep/.el-dialog__header {
		padding: 0;
	}

	.login_pop /deep/.el-tabs__nav-wrap::after {
		display: none;
	}

	.login_pop /deep/ .el-tabs__header {
		margin: 0;
	}

	.login_pop /deep/ .el-dialog__body {
		padding: 20px;
	}

	.login_pop /deep/ .el-input__inner {
		margin: 20px 0 0;
		border: none;
		height: 45px;
		line-height: 45px;
		background-color: #F2F2F2;
	}

	.ewm_wrapper /deep/ .el-input__inner {
		margin: 0;
	}

	.ewm_wrapper {
		background-color: #f2f2f2;
		margin: 20px 0 0;
		border-radius: 4px;
		padding-right: 8px;
	}

	.login_pop /deep/ .el-input__suffix {
		top: 12px;
	}

	.login_pop /deep/ .el-checkbox__inner {
		border-radius: 50%;

	}

	.login_pop_tab {
		justify-content: space-between;
		align-items: center;
	}

	.login_pop_tab img,
	.register_top img {
		width: 20px;
	}

	.login_btn {
		width: 90%;
		height: 45px;
		border-radius: 30px;
		margin: 20px auto;
	}

	.space {
		margin: 0 10px;
	}

	.top .el-button {
		background-color: #20232c;
		border: 0;
		color: white;
	}

	.con_nav {
		display: flex;
		border-radius: 15px;
		width: 70%;
		margin: 30px auto;
		min-height: 350px;
		background-color: #363C4D;
	}

	.con_l {
		color: #fff;
		width: 45%;
	}

	.course_l {
		cursor: pointer;
		margin: 40px 20px;
		display: flex;

		align-items: center;
		justify-content: space-between;
	}

	/deep/.el-carousel__container {
		height: 100%;
	}

	.course_l_cate {
		margin-left: 15px;

		span {
			white-space: nowrap;
		}
	}

	.arrow-right {
		width: 14px;
	}

	.con_r {
		height: 100%;
		width: 55%;
	}

	.con_r img {
		width: 100%;
		height: 100%;
	}

	.Boutique {
		margin: 46px 0;
		font-size: 29px;
		font-weight: 600;
		color: #171725;
	}

	.courses {
		width: 70%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

	}

	.course_item {
		border-radius: 10px;
		color: black;
		flex: 0 0 24%;
		/* height: 30px; */
		text-align: center;
		/* line-height: 30px; */
		background-color: white;
		/* 边距懒得算，css函数代替 */
		margin-right: calc(4% / 3);
		margin-bottom: calc(4% / 3);
	}

	.course_img {
		border-radius: 10px;
		width: 100%;
		height: 180px;
	}

	.course_item_footer {
		justify-content: space-between;
		color: #999;
		margin: 10px 20px;
	}

	.red {
		color: #E56B55;
	}

	/* 去除每行尾的多余边距 */
	.course_item:nth-child(4n) {
		margin-right: 0;
	}

	/* 使最后一个元素的边距填满剩余空间 */
	.course_item:last-child {
		margin-right: auto;
	}

	.course_item_title {
		overflow-y: hidden;
		height: 62px;
		text-align: left;
		padding: 10px 20px;
	}

	.more {
		cursor: pointer;
		background-color: #E4F2FF;
		color: #1B8AFB;
		width: 200px;
		border-radius: 10px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		margin: 30px auto;
	}
</style>