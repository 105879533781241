import axios from "axios";
import router from '../router/index.js'
// import {
//   Toast
// } from "mint-ui";
import {
	Message
} from "element-ui";
var HttpRequest = {
	getRequest({
		url,
		data = {},
		method = "GET"
	}) {
		return new Promise((resolve, reject) => {
			this._getRequest(url, resolve, reject, data, method);
		});
	},
	_getRequest: function(url, resolve, reject, data = {}, method = "GET") {
		url = 'http://222.75.31.198:8080' + url;
		// http://192.168.3.94:8080
		// http://jiananyun.beifan888.cn:5239
		// http://aqjy.shnxmyjt.com/prod-api
		var token = localStorage.getItem("token")
		let format = method.toLocaleLowerCase() === 'get' ? 'params' : 'data';
		axios({
			url: url,
			method: method,
			[format]: data ? data : '',
			headers: {
				"content-type": "application/json",
				'appToken': token
			},

		}).then(res => {
			resolve(res);
			// return
			if (res.data.code == 40001) {
				Message.error('请登录后操作')
				router.push({
					path: '/login'
				})
			} else if (res.data.code != 200) {
				Message.error(res.data.msg)
			} else {
				resolve(res);
			}

			//  else if (res.data.code == 0 || res.data.code == 10003) {
			// 	Message.error(res.data.msg)
			// } else if (res.data.code == 1) {
			// 	resolve(res);
			// }
		}).catch((res) => {
			console.log(res)
			Message.error(res.data.msg)
			resolve(res);
			return

			console.log(res)

			if (res.data.code == 401) {
				Message.error('请登录后操作')
				router.push({
					path: '/login'
				})
			} else if (res.data.code == -1) {
				resolve(res);
			} else if (res.data.code == 0) {
				Message.error(res.data.msg)
			} else if (res.data.code == 1) {
				resolve(res);
			}
			return
			reject();
			Message.error(res.data.msg)
			// if (res.data.code == 401) {

			// 	Message.error('请登录后操作')
			// 	return
			// 	// this.$router.push({
			// 	// 	path: 'login/index'
			// 	// })
			// }
		})

		// console.log(token, '登录')
		// if (token == '' || token == null) {

		// 	router.push({
		// 		path: '/login'
		// 	})
		// }
	}

};
export {
	HttpRequest
};