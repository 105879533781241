// 导出方法2

// export function multiply(x,y){
//     return x * y;
// }

// 导出方法2
// function v1(){ ... }
// function v2(){ ... }
// export {
//      v1 as streamV1,
//      v2 as streamV2,
//      v2 as streamLaterstVersion
// };
// const moduleA = {
//     state: () => ({ count:0 }),
//     mutations: {},
//     actions: {},
//     getters: {}
//   }
  
//   const moduleB = {
//     state: () => ({count:0}),
//     mutations: {},
//     actions: {}
//   }
  module.exports = {
      moduleA: {
        state: () => ({ count:0 }),
        mutations: {},
        actions: {},
        getters: {}
      },
      moduleB: {
        state: () => ({ count:2 }),
        mutations: {},
        actions: {},
      }
  } 